import React, { useState, useEffect } from "react";

const ThemeToggle: React.FC = () => {
  // State to manage dark mode
  const [darkMode, setDarkMode] = useState<boolean>(() => {
    // Check localStorage first
    const storedTheme = localStorage.getItem("theme");

    // If there is a stored theme, return it
    if (storedTheme) {
      return storedTheme === "dark";
    }

    // Otherwise, check browser preference
    return true;
  });

  // Toggle function
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("theme", newMode ? "dark" : "light");
      return newMode;
    });
  };

  // Apply dark mode class to HTML element on load and whenever it changes
  useEffect(() => {
    const htmlElement = document.documentElement;
    if (darkMode) {
      htmlElement.classList.add("dark");
    } else {
      htmlElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <button
      onClick={toggleDarkMode}
      className="h-9 w-9 flex justify-center items-center hover:bg-bg-secondary rounded-full"
      title={darkMode ? `Switch to light`: `Switch to dark`}
    >
      {darkMode ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#e8e8e5"
        >
          <path d="M480-371q45.46 0 77.23-31.77Q589-434.54 589-480q0-45.46-31.77-77.23Q525.46-589 480-589q-45.46 0-77.23 31.77Q371-525.46 371-480q0 45.46 31.77 77.23Q434.54-371 480-371Zm0 91q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM80-434.5q-19.15 0-32.33-13.17Q34.5-460.85 34.5-480t13.17-32.33Q60.85-525.5 80-525.5h80q19.15 0 32.33 13.17Q205.5-499.15 205.5-480t-13.17 32.33Q179.15-434.5 160-434.5H80Zm720 0q-19.15 0-32.33-13.17Q754.5-460.85 754.5-480t13.17-32.33Q780.85-525.5 800-525.5h80q19.15 0 32.33 13.17Q925.5-499.15 925.5-480t-13.17 32.33Q899.15-434.5 880-434.5h-80Zm-320-320q-19.15 0-32.33-13.17Q434.5-780.85 434.5-800v-80q0-19.15 13.17-32.33Q460.85-925.5 480-925.5t32.33 13.17Q525.5-899.15 525.5-880v80q0 19.15-13.17 32.33Q499.15-754.5 480-754.5Zm0 720q-19.15 0-32.33-13.17Q434.5-60.85 434.5-80v-80q0-19.15 13.17-32.33Q460.85-205.5 480-205.5t32.33 13.17Q525.5-179.15 525.5-160v80q0 19.15-13.17 32.33Q499.15-34.5 480-34.5ZM222.17-673.93l-43-42Q165.5-728.61 166-747.76t13.17-33.07q13.44-13.67 32.59-13.67 19.15 0 32.07 13.67l42.24 43q12.67 13.44 12.55 31.71-.12 18.27-12.55 31.95-12.68 13.67-31.45 13.41-18.77-.26-32.45-13.17Zm494 494.76-42.24-43q-12.67-13.44-12.67-32.09 0-18.65 12.67-31.57 12.68-13.67 31.45-13.17t32.45 13.17l43 41.76q13.67 12.68 13.17 31.83t-13.17 33.07q-13.44 13.67-32.59 13.67-19.15 0-32.07-13.67Zm-42-494.76Q660.5-686.61 661-705.38t13.17-32.45l41.76-43q12.68-13.67 31.83-13.17t33.07 13.17q13.67 13.44 13.67 32.59 0 19.15-13.67 32.07l-43 42.24q-13.44 12.67-31.71 12.55-18.27-.12-31.95-12.55Zm-495 494.76q-13.67-13.44-13.67-32.59 0-19.15 13.67-32.07l43-42.24q13.44-12.67 32.09-12.67 18.65 0 31.57 12.67 13.67 12.68 13.17 31.45t-13.17 32.45l-41.76 43Q231.39-165.5 212.24-166t-33.07-13.17ZM480-480Z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#2b342e"
        >
          <path d="M480.24-116.41q-153.63 0-258.73-104.98Q116.41-326.37 116.41-480q0-133.93 84.74-235.43t223.31-123.05q15.39-3.43 27.54 1.35 12.15 4.78 19.95 14.02 7.79 9.24 9.6 22.2 1.82 12.95-4.75 26.11-13.89 25.04-21.31 51.65-7.42 26.61-7.42 55.5 0 91.69 64.32 155.88 64.33 64.18 156.22 64.18 28.37 0 56.48-7.44 28.11-7.45 50.91-20.58 12.91-5.8 25.13-4.11 12.22 1.7 21.1 8.13 9.88 6.44 14.66 18.23 4.78 11.8 1.59 27.95Q820.17-291 717.63-203.71q-102.54 87.3-237.39 87.3Zm0-91q81.78 0 147.84-43.72 66.05-43.72 98.29-114.78-17.61 4.04-35.1 6.32-17.49 2.29-34.86 1.81-122.04-4.07-207.94-89.37-85.9-85.31-90.45-209.26-.24-17.37 1.93-34.98 2.16-17.61 6.44-34.98-70.82 32.48-114.78 98.65-43.96 66.18-43.96 147.72 0 112.93 79.83 192.76 79.83 79.83 192.76 79.83Zm-13.11-259.48Z" />
        </svg>
      )}
    </button>
  );
};

export default ThemeToggle;

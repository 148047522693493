import React from "react";
import phubHero from "../assets/floor-level.png";
import nursePopover from "../assets/select-nurse-popover.png";
import PortfolioCard from "../components/PortfolioCard";
import TextGroup from "../components/TextGroup";
import TextBlock from "../components/TextBlock";
import PostSectionTitle from "../components/PostSectionTitle";
import PostImage from "../components/PostImage";
import PostLayout from "../components/PostLayout";
import speechBubbleCommentPreview from "../assets/bubble-cp.png";
import useGoogleAnalytics from "../googleAnalytics";

export default function Home() {
  useGoogleAnalytics();
  return (
    <PostLayout homePage>
      <div className="bg-bg-primary pt-20">
        <div className="container mx-auto md:my-12 pb-20">
          <div className="md:h-[200px] flex flex-col items-center mb-16">
            <h1 className="font-display md:text-8xl text-6xl font-bold ">
              Chiamaka
            </h1>
            <p className="font-normal text-xl text-text-secondary">
              Product Designer
            </p>
          </div>

          <div className="flex flex-col gap-20">
            <PortfolioCard
              title="Designing for complex data"
              subtitle="Providing facility operators with meaningful insights from building data despite data limitiations"
              imageSrc={phubHero}
              imageAlt="hero"
              tags="Design • Research • Etc"
              linkPath="/precision-hub"
              company="THOUGHTWIRE • Smart Buildings • 2023+"
            />
            <PortfolioCard
              company="QUORA • Social Media • 2021"
              title={"An Experiment with Comment Previews"}
              subtitle="Making the Quora feed short content friendly"
              imageSrc={speechBubbleCommentPreview}
              imageAlt="hero"
              tags="Design • Research • Etc"
              linkPath="/comment-previews"
            />
            <PortfolioCard
              title="An Assignment Tool for Decision Making"
              subtitle="Supporting Charge Nurses in managing patient-assignments throughout every stage of the workflow"
              imageSrc={nursePopover}
              imageAlt="hero"
              tags="Design • Research • Etc"
              linkPath="/assignment-tool"
              company="THOUGHTWIRE • Healthcare • 2024"
            />
          </div>
        </div>
        <div className="bg-bg-secondary py-12 px-4">
          <div className="container max-w-4xl mx-auto py-12">
            <div className="max-w-2xl relative">
              <TextGroup>
                <PostImage
                  src="https://res.cloudinary.com/drsp4xifi/image/upload/v1624893246/Portfolio_v2/About/Ellipse_75_ujgztj.png"
                  alt=""
                  className="w-[150px] ml-0 my-8"
                />

                <PostSectionTitle>Hello! 👋🏾</PostSectionTitle>
                <TextBlock>
                  My name is Chiamaka <em>(Chee-ah-mah-kah)</em>, a product
                  designer at ThoughtWire.
                </TextBlock>
                <TextBlock>
                  I enjoy working in environments that leverage data — whether
                  collecting, analyzing, or using it to drive decisions. I love
                  to build the things I design and enjoy prototyping with code.
                </TextBlock>
                <TextBlock>
                  When I’m not working, I'm trying out new recipes 🍱 🍚 🍉 or
                  watching a movie 🍿.
                </TextBlock>
                <TextBlock>
                  Find me on{" "}
                  <a
                    href="https://www.linkedin.com/in/chiamakaiwuanyanwu/"
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold underline text-bg-accent"
                  >
                    LinkedIn
                  </a>
                </TextBlock>
              </TextGroup>
            </div>
          </div>
        </div>
      </div>
    </PostLayout>
  );
}

import React from "react";
import PostTitle from "../components/PostTitle";
import PostLayout from "../components/PostLayout";

import shortContentSketch from "../assets/short-content-sketch.png";
import speechBubbleCommentPreview from "../assets/bubble-cp.png";
import roundedBoxCommentPreview from "../assets/rounded-box-cp.png";
import standardCommentPreview from "../assets/existing-cp.png";
import finalCommentPreview from "../assets/roundedbox.png";
import commentPreviewsHero from "../assets/comment-previews-hero.png";
import PostSectionTitle from "../components/PostSectionTitle";
import useGoogleAnalytics from "../googleAnalytics";

export default function CommentPreviews() {
  useGoogleAnalytics();
  return (
    <PostLayout previousPath="/precision-hub" nextPath="/assignment-tool">
      <PostTitle
        title="An Experiment with Comment Previews"
        company="QUORA • Social Media"
        time="2021"
        role="Design, Development, A/B Testing"
        small
      >
        During my internship at Quora, I worked on the distribution team and
        experimented with comment previews to increase engagement with short
        content. The results were promising but came with unexpected trade-offs,
        which highlighted the challenges of balancing metrics and their impact
        on design decisions.
      </PostTitle>

      <img
        src={commentPreviewsHero}
        alt="Screenshot of the quora app showing a short piece of content with a comment preview"
        className="my-24 md:w-full h-[400px] md:h-auto object-cover"
      />

      <section className="flex flex-col max-w-5xl mx-auto min-h-[60vh] justify-center p-4">
        <h2 className="uppercase text-text-tertiary font-medium">
          The Problem
        </h2>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-4 mb-12">
          <div className="flex flex-col gap-4">
            <PostSectionTitle large>
              Short content <br /> did not get enough reach
            </PostSectionTitle>
          </div>
          <div className="flex flex-col gap-4">
            <p>
              Short content can be just as insightful as long content, but on
              Quora, we noticed it often didn’t get enough reach, regardless of
              its quality. As part of a broader effort to make the Quora feed
              more short-content-friendly, I explored comment previews to boost
              engagement with short posts.
            </p>
          </div>
        </div>
      </section>
      <section className="flex flex-col max-w-5xl mx-auto min-h-[90vh] justify-center p-4 border-t-4 border-dashed border-t-bg-tertiary">
        <h2 className="uppercase text-text-tertiary font-medium">
          The Discovery
        </h2>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-4 mb-12">
          <div className="flex flex-col gap-4">
            <PostSectionTitle large>
              Limited ways to interact with short content
            </PostSectionTitle>
          </div>
          <div className="flex flex-col gap-4">
            <p>
              On Quora, long-form content is collapsed by default, allowing
              users to expand the post if the preview captures their interest.
              This expandability feature generates signals that help us learn
              about user interests and tailor content distribution.
            </p>
            <p>
              However, short content lacks this feature, leaving less room for
              user interaction beyond comments and shares. So, we hypothesized
              that by making comments more prominent we could increase
              engagement, and learn more about people who value short content.
            </p>
            <figure>
              <img
                className="h-[150px] dark:invert dark:brightness-100"
                src={shortContentSketch}
                alt=""
              />
              <figcaption className="text-sm text-text-secondary mt-4 italic">
                With long content users could interact by tapping to read more
              </figcaption>
            </figure>
          </div>
        </div>
      </section>

      <section className="flex flex-col max-w-5xl mx-auto min-h-[90vh] justify-center p-4 border-t-4 border-dashed border-t-bg-tertiary">
        <h2 className="uppercase text-text-tertiary font-medium">
          Design Decisions
        </h2>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 items-center mt-4 mb-12">
          <div className="flex flex-col gap-4">
            <PostSectionTitle large>
              A compact UI that minimized space
            </PostSectionTitle>
          </div>
          <div className="flex flex-col gap-4">
            <p>
              The design goal for the comment previews was to prompt users to
              engage with short posts by highlighting the top comment and
              providing a clear call to action. To minimize vertical space, I
              kept the preview text to one line and tested different variants to
              determine which design was most effective.
            </p>
          </div>
        </div>
        <div className="min-h-72  rounded-2xl grid md:grid-cols-3 grid-cols-1 gap-12 px-12">
          <figure>
            <img
              className="w-[80%] mx-auto shadow-sm rounded-lg"
              src={roundedBoxCommentPreview}
              alt=""
            />
            <figcaption className="text-sm text-text-secondary mt-4 italic">
              Rounded Box: A large tap target embedded in the story. I had a
              good feeling about this getting more clicks.
            </figcaption>
          </figure>
          <figure>
            <img
              className="w-[80%] mx-auto shadow-sm rounded-lg"
              src={standardCommentPreview}
              alt=""
            />
            <figcaption className="text-sm text-text-secondary mt-4 italic">
              Standard Comment: With a similar design to the existing comment
              UI, is identifiable as a comment.
            </figcaption>
          </figure>
          <figure>
            <img
              className="w-[80%] mx-auto shadow-sm rounded-lg"
              src={speechBubbleCommentPreview}
              alt=""
            />
            <figcaption className="text-sm text-text-secondary mt-4 italic">
              Bubble: The speech bubble is an easily identifiable pattern for
              comments or messages.
            </figcaption>
          </figure>
        </div>
      </section>

      <div className="bg-bg-accent text-bg-primary mt-16">
        <section className="flex flex-col max-w-5xl mx-auto min-h-[60vh] justify-center p-4">
          <h2 className="uppercase text-white/50 font-medium dark:text-black/50">
            DEVELOPMENT & A/B TESTING
          </h2>
          <div className="flex flex-col gap-4 mt-4 mb-12">
            <PostSectionTitle large>
              <span className="md:text-7xl text-5xl">+12%</span> comment expands
              ... at a cost
            </PostSectionTitle>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
              <p>
                I built the different variants using React and collaborated with
                a data scientist to set up the A/B tests, including identifying
                relevant metrics. Throughout the test period, I monitored the
                experiments and debugged any issues to ensure the test ran as
                expected.
              </p>
              <p>
                At the end of the test, the <em>bubble</em> variant led to the
                highest increase in comment expansion (12%), but it had the most
                negative impact on other metrics. The <em>rounded box</em> and{" "}
                <em>standard</em> variants showed similar increases (10%), with
                the <em>rounded box</em> having the least negative impact
                overall.
              </p>
            </div>
          </div>
        </section>
      </div>

      <section className="flex flex-col max-w-5xl mx-auto min-h-[90vh] justify-center p-4">
        <h2 className="uppercase text-text-tertiary font-medium">
          ITERATIONS AND REFINEMENT
        </h2>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-4 mb-12">
          <div className="flex flex-col gap-4">
            <PostSectionTitle large>
              Turning tradeoffs into impact
            </PostSectionTitle>
          </div>
          <div className="flex flex-col gap-4">
            <p>
              After the initial experiment, I focused on the rounded box variant
              for further testing, as it had the least negative impact on other
              metrics. I made several adjustments to mitigate the impact, but
              none were successful.
            </p>
            <p>
              Ultimately, the rounded box variant was adopted as a replacement
              for the existing comment preview in long-form content, after
              showing positive results in testing. I noticed the updated design
              shipped after my internship ended which was great! 🎉
            </p>
            <figure className="mt-4">
              <img
                className="w-[80%] shadow-md rounded-lg"
                src={finalCommentPreview}
                alt=""
              />
              <figcaption className="text-sm text-text-secondary mt-4 italic">
                The rounded box variant shipped as a replacement for comment
                previews on long form content after my internship.
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
    </PostLayout>
  );
}

import React from "react";

interface Props {
  children: any;
  className?: string;
  small?: boolean;
  contrast?: boolean;
}

export default function PostSection({ children, contrast, small }: Props) {
  return (
    <section
      className={`${small ? "max-w-5xl" : "container"} relative ${
        contrast ? "bg-bg-secondary" : ""
      } md:min-h-[80vh] mx-auto p-4 pt-12 grid md:grid-cols-2 grid-cols-1 gap-12 items-center`}
    >
      {children}
    </section>
  );
}

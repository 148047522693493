import React from "react";

interface Props {
  src: string;
  alt: string;
  className?: string;
  caption?: string
}

export default function PostImage({ src, alt, className, caption }: Props) {
  return (
    <figure className="relative">
      <img
        src={src}
        alt={alt}
        className={`mx-auto ${className ? className : "h-[450px]"}`}
      />
      {!!caption && <figcaption className="font-display italic text-text-secondary text-sm text-center mt-4 md:w-[65%] m-auto">{caption}</figcaption>}
    </figure>
  );
}

import React from "react";
import PostTitle from "../components/PostTitle";
import PostHero from "../components/PostHero";
import PostSection from "../components/PostSection";
import TextBlock from "../components/TextBlock";
import PostSectionTitle from "../components/PostSectionTitle";
import TextGroup from "../components/TextGroup";
import PostLayout from "../components/PostLayout";
import PostImage from "../components/PostImage";
import systemsSketch from "../assets/systems.png";
import statusMetricImage from "../assets/status-metric-interface.png";
import statusMetric from "../assets/status.png";
import floorLevelTooltipImage from "../assets/floor-level-tooltip.png";
import floorDetailsImage from "../assets/floor-level.png";
import heroImg from "../assets/phub-hero.png";
import deviceDetailsImage from "../assets/device-details.png";
import useGoogleAnalytics from "../googleAnalytics";

export default function PrecisionHub() {
  useGoogleAnalytics();
  return (
    <PostLayout nextPath="/comment-previews">
      <PostTitle
        title="Designing for complex data"
        company="THOUGHTWIRE • Smart Buildings"
        time="2023 - Now"
        role="Product Design"
        output="Mockups, Prototypes, React components"
      >
        <span>
          The Precision Hub (PHub) platform creates digital representations of
          buildings which helps facility operators monitor and identify building
          issues. However, deploying PHub comes with challenges due to data
          limitations from integrating with multiple systems. By staying focused
          on user goals, we are able to provide meaningful insights while
          creating an experience that helps users trust the platform, even with
          these constraints.
        </span>
      </PostTitle>
      <PostHero
        src={heroImg}
        alt="A screenshot of Phub's Building State page showing a map view, with filters and details of a floor in the side panel."
      />

      <PostSection>
        <div className="flex flex-col gap-24">
          <TextGroup>
            <h4 className="uppercase font-bold text-lg text-text-tertiary mb-14 relative -top-10 tracking-wide">
              The Challenge
            </h4>
            <PostSectionTitle>
              Dealing with large volumes of data presents many challenges
            </PostSectionTitle>
            <TextBlock>
              Buildings using PHub generate a lot of data, especially
              multi-storey buildings with data coming from various sources,
              including HVAC, lighting, security systems, and even CSV files.
            </TextBlock>
            <TextBlock>
              Configuring the platform for a building is challenging, and often
              involves issues like missing data, mapping errors, and occasional
              system malfunctions.
            </TextBlock>
          </TextGroup>
          <TextGroup>
            <PostSectionTitle>
              Facility operators need to trust PHub
            </PostSectionTitle>
            <TextBlock>
              In a building, various issues can arise, such as uncomfortable
              temperatures in a meeting room or a forced door, and facility
              operators expect to see these issues in PHub. If the information
              displayed does not align with the actual state of the building, it
              can lead to confusion and lengthen the troubleshooting process.
            </TextBlock>
            <TextBlock>
              Challenges like these shape the design decisions behind Phub
              features, as it is essential that users trust the platform, even
              with data limitations.
            </TextBlock>
          </TextGroup>
        </div>
        <PostImage
          src={systemsSketch}
          alt="Diagram showing PHub at the center, with arrows connecting it to five data sources labeled HVAC, Bookings, CSV files, Work Orders, and Lighting. This illustrates how PHub integrates with multiple systems to collect data about a building."
          className="h-[350px] dark:invert dark:brightness-100"
          caption="PHub integrates with multiple systems to collect data about a building."
        />
      </PostSection>

      <div className="py-24 my-24 bg-bg-secondary">
        <PostSection>
          <TextGroup>
            <h4 className="uppercase font-bold text-lg text-text-tertiary absolute top-0 tracking-wide">
              Key design decisions
            </h4>
            <PostSectionTitle>
              Components that deliver meaningful insights
            </PostSectionTitle>
            <TextBlock>
              When displaying metrics in PHub, our goal is to help users gain
              meaningful insights, particularly with aggregated data. A security
              officer may find it more useful to see that there is 1 forced door
              on a floor rather than simply seeing there are 10 doors.
            </TextBlock>
            <TextBlock>
              To address this, I built components for displaying metrics and
              collaborated with an engineer to ensure the components could be
              configured to highlight the most important details. For example,
              the status component’s subtitle displays the most important
              status, allowing us to prioritize "forced" over open or closed
              when aggregating data for doors.
            </TextBlock>
          </TextGroup>
          <TextGroup>
            <PostImage
              src={statusMetric}
              alt="Screenshot of a UI component labeled 'Doors' with a subtitle '1 forced' and a red door icon, followed by an information icon."
              className="h-[100px] shadow-sm"
            />
            <PostImage
              src={statusMetricImage}
              alt="Code snippet displaying an interface for a metric, with properties including label set to 'Doors,' subtitle set to '1 forced,' icon set to 'Door,' iconColor set to 'AlertPrimary,' and variant set to 'Grid.'"
              className="rounded-2xl w-full scale-90 shadow-sm"
              caption="The metric component's subtitle displays the most important status, ensuring we deliver valuable insights."
            />
          </TextGroup>
        </PostSection>
        <PostSection>
          <TextGroup>
            <PostSectionTitle>
              Enhancing Clarity through Information Hierarchy
            </PostSectionTitle>
            <TextBlock>
              Another way we build trust is by organizing information clearly.
              During a call, a user while looking at the floor details panel was
              confused when they saw 2 alerts listed in the <em>alerts</em>{" "}
              section, but 8 in the <em>current metrics</em> section. The
              confusion was because the <em>current metrics</em> section
              displayed aggregated data while the <em>alerts</em> section showed
              floor-level data.{" "}
            </TextBlock>
            <TextBlock>
              To fix this, we separated aggregate from floor-level data to help
              users understand the scope of the data. Arriving at this solution
              required close collaboration with engineers to understand how we
              aggregated data. With this knowledge, I was able to design a
              holistic solution that we applied across similar contexts.{" "}
            </TextBlock>
          </TextGroup>
          <PostImage
            src={floorDetailsImage}
            alt="A screenshot of the floor details panel for Floor 2 showing metrics for doors, lights, occupancy, and alerts for temperature and door issues."
            className="rounded-2xl h-[500px] shadow-sm"
            caption="The floor details panel separates aggregated data from floor level data which helps in understanding the scope of the data"
          />
        </PostSection>
        <PostSection>
          <TextGroup>
            <PostSectionTitle>
              Removing Ambiguity with Contextual Help
            </PostSectionTitle>
            <TextBlock>
              Sometimes we encounter data that may not have some details we
              expect, such as an alert linked to a device with a floor location
              but no room information. In such cases, we state what we know
              about the data rather than making assumptions or leaving it
              ambiguous.
            </TextBlock>
            <TextBlock>
              To do this effectively involves asking questions about the data.
              For example, in the case of an alert, instead of implying that it
              affects the entire floor, we specify that it is from a device on
              the second floor using a tooltip. This clarity helps users
              understand the context of the information and reinforces trust in
              the data presented.
            </TextBlock>
          </TextGroup>
          <PostImage
            src={floorLevelTooltipImage}
            alt="A screenshot showing the Floor level header and a tooltip explaining what it means"
            className="rounded-2xl h-[200px] shadow-sm"
            caption="We provide additional context about the data using tooltips, to remove ambiguity"
          />
        </PostSection>

        <PostSection>
          <TextGroup>
            <PostSectionTitle>
              Handling Missing Data ... Gracefully
            </PostSectionTitle>
            <TextBlock>
              Sometimes, PHub expects certain data points that don’t exist in
              the source system. Other times, data may be innaccessible due to
              user permissions, or there may simply be no data available, such
              as when there are no work orders on a floor.
            </TextBlock>
            <TextBlock>
              Addressing these situations distinctly helps users understand the
              system better and makes it more predictable. For instance, a user
              with permission to view work orders will see a message indicating
              that there are no work orders if none exist, while a user without
              permissions won’t see the work order section at all. Additionally,
              fallbacks like 'NA' clarify why a data point is unavailable,
              rather than leaving a blank space.
            </TextBlock>
          </TextGroup>
          <PostImage
            src={deviceDetailsImage}
            alt="A screenshot showing the zone occupancy metric for a zone, with a value of N/A"
            className="rounded-2xl h-[200px] shadow-sm"
            caption="Zones without occupancy data display N/A as a fallback instead of a blank space or 0 which can be misleading."
          />
        </PostSection>
      </div>
      <PostSection>
        <TextGroup>
          <h4 className="uppercase font-bold text-lg text-text-tertiary absolute top-0 tracking-wide">
            The Impact
          </h4>
          <PostSectionTitle large>
            Configuring PHub is easier, and debugging issues is quicker
          </PostSectionTitle>
        </TextGroup>
        <TextGroup>
          <TextBlock>
            Components like the metric component prompt the right questions when
            Phub is configured for a new building ensuring we deliver valuable
            insights everytime.
          </TextBlock>
          <TextBlock>
            Additionally, the clarity in the way data is presented helps
            facility operators understand the information better and has also
            improved communication between the product and engineering teams,
            particularly when troubleshooting issues.
          </TextBlock>
        </TextGroup>
      </PostSection>
    </PostLayout>
  );
}

import React from "react";

interface Props {
  children: any;
  large?: boolean
}

export default function PostSectionTitle({ children, large }: Props) {
  return <h3 className={`font-bold font-display ${large ? "md:text-5xl" : "md:text-4xl"} md:leading-tight text-3xl text-balance`}>{children}</h3>;
}
